<template>
	<CForm>
		<CRow>
			<CCol sm="12">
				<AddEditForm :headerTitle="$t('pages.warehouse.buyproductbyagent')" :onGoBack="onGoBack" :showDelete="false">
					<template v-slot:headerAddEdit>
						<button type="button" name="add-addDistribution" class="btn btn-primary" @click="onBuying">
							{{ $t('common.buying') }}
						</button>						
					</template>
					<template #content>
						<Accordion :headerTitle="$t('common.generalInfo')" v-if="agentOrderDetail != null">
							<template v-slot:body>
								<CForm>		
									<CRow v-if="isShowAgentList">
										<CCol sm="7">											
											<CSelect horizontal :options="agentOptions" :value.sync="agentOrderDetail.buyingAgentId">
												<template #label>
													<div class="col-sm-3 col-form-label">
														<b>{{ $t('pages.warehouse.selectAgent') }}</b>
														<small class="requiredInput"> (*)</small>
													</div>
												</template>
											</CSelect>
										</CCol>
									</CRow>
									<CRow>
										<CCol sm="7">
											<div class="row">
												<div class="col-md-4">
													<b><label>{{ $t('pages.product.numberOfProducts') }}:</label></b>
												</div>
												<div class="col-md-6">
													<p><b>{{  numberOfProducts }}</b></p>
												</div>
											</div>
											<div class="row">
												<div class="col-md-4">
													<b><label>{{ $t('pages.product.totalAmount') }}:</label></b>
												</div>
												<div class="col-md-6">
													<p><b><label v-text="$func.formatCurrency(totalAmount)" /></b></p>
												</div>
											</div>
											<!--<div class="row">
												<div class="col-md-4">
													<b><label>{{ $t('pages.product.totalDebtPoints') }}:</label></b>
												</div>
												<div class="col-md-6">
													<p><b><label v-text="$func.formatNumber(totalDebtPoints)" /></b></p>
												</div>
											</div>-->
											<div class="row">
												<div class="col-md-4">
													<b><label>{{ $t('pages.product.provisionalAmount') }}:</label></b>
												</div>
												<div class="col-md-6">
													<p><b><label v-text="$func.formatCurrency(provisionalAmount)" /></b></p>
												</div>
											</div>
										</CCol>
									</CRow>
									<CRow>
										<CCol sm="7">
											<CSelect horizontal :options="shippingAddressOptions" :value.sync="agentOrderDetail.shippingAddressId">
												<template #label>													
													<div class="col-sm-3 col-form-label">
														<b>{{ $t('common.shippingAddress') }}</b>
													</div>
												</template>
											</CSelect>
										</CCol>
										<CCol>
											<button type="button" name="add-shippingAddress" class="btn btn-primary" @click="onAddShippingAddress">
												{{ $t('common.addShippingAddress') }}
											</button>
										</CCol>
									</CRow>
									<CRow>
										<CCol>
											<button type="button" name="add-addDistribution" class="btn btn-primary" @click="onBuying">
												{{ $t('common.buying') }}
											</button>
										</CCol>
									</CRow>
									<CRow>
										<CCol sm="12">
											<div style="padding:15px">
												<h5><label>{{ $t('pages.product.listProductInOrder') }}</label></h5>
											</div>
											<div>
												<CDataTable :items="listProductOfOrder"
															:fields="orderItemField"
															:noItemsView="{ noItems: $t('pages.product.noProductSelected') }"
															hover
															striped
															border
															small
															fixed
															:items-per-page="itemsPerPage">
													<template #product_wholesalePrice="{item}">
														<td>
															<label v-text="$func.formatCurrency(item.price)" />
														</td>
													</template>
													<template #product_debtPoints_text="{item}">
														<td>
															<label v-text="$func.formatNumber(item.debtPoints)" />
														</td>
													</template>
													<template #product_amount="{item}">
														<td>
															<label v-text="showAmountOfProductItem(item)" />
														</td>
													</template>
													<template #product_quantity="{item, index}">
														<td>
															<div class="quantity">
																<div class="quantity-minus" v-on:click="onAddRemoveQuantity(false,item, index)"><CIcon name="cil-minus"></CIcon></div>
																<div>
																	<input class="quantity-input" :value="item.quantity" @keypress="$func.onlyNumbers" @blur="blurEventHandlerQuantity($event, item)" />
																</div>
																<div class="quantity-plus" v-on:click="onAddRemoveQuantity(true,item, index)"><CIcon name="cil-plus"></CIcon></div>
															</div>
														</td>
													</template>
													<template #order_detail_actions="{item, index}">
														<td class="py-2">
															<CButton class="btn-danger"
																	 variant="outline"
																	 square
																	 size="sm"
																	 @click="onRemoveProductFromList(index)">
																{{ $t('common.remove') }}
															</CButton>
														</td>
													</template>
												</CDataTable>
											</div>
										</CCol>
									</CRow>
								</CForm>
							</template>
						</Accordion>

						<Confirmation ref="confirmation"></Confirmation>
					</template>							
				</AddEditForm>
			</CCol>
		</CRow>		
		<CRow>
			<CCol sm="12">
				<ListForm :headerTitle="$t('common.products')" :onSearch="onSearchProduct">
					<template v-slot:searchBox>
						<CForm>
							<CRow>
								<CCol md="6">
									<CInput :label="$t('pages.product.productName')" v-model="searchProductName" :placeholder="$t('pages.product.enterProductName')" horizontal v-on:keyup.enter="onSearchProduct"></CInput>
								</CCol>
							</CRow>
						</CForm>
					</template>
					<template v-slot:list>
						<CDataTable :items="allProducts.data"
									:fields="fields"
									:noItemsView="{ noItems: $t('common.nodatafound') }"
									hover
									striped
									border
									small
									fixed
									:loading="isLoadingProducts"
									:items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }"
									:items-per-page="itemsPerPage"
									@pagination-change="onItemsPerPageChange"
									pagination>
							<template #footer v-if="allProducts != null && allProducts.length > 0">
								<tfoot>
									<tr>
										<td :colspan="10">
											<span>{{ $t('common.totalRecords') }}</span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allProducts == null ? 0: allProducts.length}}</span><span style="padding-left:5px"></span>
										</td>
									</tr>
								</tfoot>
							</template>

							<template #defaultPictureModel="{item}">
								<td>
									<img :src="item.defaultPictureModel.thumbImageUrl" width="50" height="50" v-if="item.defaultPictureModel != null && item.defaultPictureModel.thumbImageUrl" />
									<img :src="'/content/images/no-image.gif'" width="50" height="50" v-else>
								</td>
							</template>
							<template #product_wholesalePrice="{item}">
								<td>
									<label v-text="$func.formatCurrency(item.wholesalePrice)" />
								</td>
							</template>							
							
							<template #user_detail_actions="{item, index}">
								<td class="py-2">
									<CButton color="primary"
											 variant="outline"
											 square
											 size="sm"
											 @click="onAddToOrders(item, index)">

										{{ $t('common.addToOrders') }}
									</CButton>
								</td>
							</template>
						</CDataTable>
					</template>
				</ListForm>				
			</CCol>
		</CRow>
		<!--<ShippingAddressList/>-->
	</CForm>
</template>

<script>
    import { mapGetters } from 'vuex'
	import i18n from '@/plugins/i18n'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
	import ListForm from '@/components/ListForm.vue'
    import Confirmation from '@/components/Confirmation.vue'
	/*import ShippingAddressList from '@/components/ShippingAddressList.vue'*/

	export default ({
        name: 'AddDealerOrders',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
                itemsPerPage: 10,				
				searchProductName: '',
				listProductOfOrder: [],
				numberOfProducts: 0,
				provisionalAmount: 0,
				totalDebtPoints: 0,
                totalAmount: 0,
                fields: [
                    { key: 'defaultPictureModel', label: '' },
                    { key: 'name', label: i18n.t('common.name') },                   
                    { key: 'product_wholesalePrice', label: i18n.t('pages.product.wholesalePrice') },
                    { key: 'packagingSpecification', label: i18n.t('pages.product.packagingSpecification') },                   
                    {
                        key: 'user_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
				],
				orderItemField: [
                    { key: 'name', label: i18n.t('pages.product.productName') },
					{ key: 'product_wholesalePrice', label: i18n.t('pages.product.wholesalePrice') },
                    { key: 'product_debtPoints_text', label: i18n.t('common.debtPoints') },					
					{ key: 'product_quantity', label: i18n.t('pages.product.quantity') },
                    { key: 'product_amount', label: i18n.t('common.amount') },
                    {
                        key: 'order_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
				],
				isShowAgentList: false
			}
		},
        components: {			
            Accordion, AddEditForm, ListForm, Confirmation,
			/*ShippingAddressList*/
		},
		computed: {
            ...mapGetters('transferInventory', ['agentOrderDetail', 'hasError', 'errorMessage']),
			...mapGetters('product', ['allProducts', 'isLoadingProducts']),
			...mapGetters('agent', ['agentList']),
			...mapGetters('location', ['listShippingAddress']),

            shippingAddressOptions() {
                let options = [];
                if (this.listShippingAddress != null && this.listShippingAddress.length > 0) {
                    options = this.listShippingAddress.map(item => {
                        return { value: item.id, label: item.fullAddress };
                    });                                   
                }

				//options.push({ value: 0, label: i18n.t('common.shippingAddressForAgent') });

                options.splice(0, 0,
                    {
                        value: '-1',
                        label: i18n.t('common.selectShippingAddress')
					}); 

                options.splice(1, 0,
                    {
                        value: '0',
                        label: i18n.t('common.shippingAddressForAgent')
                    }); 
                		
                return options;
			},
			agentOptions() { 
                let options = [];
                if (this.agentList != null && this.agentList.length > 0) {
                    options = this.agentList.map(item => {
                        return { value: item.id, label: item.name };
                    });

                    options.splice(0, 0,
                        {
                            value: '0',
                            label: i18n.t('pages.warehouse.selectAgent')
                        });                   
                }

                return options;
			}
		},
		methods: {			
            blurEventHandlerQuantity(event, item) {
				let curVal = parseInt(event.target.value);

				if (isNaN(curVal))
					curVal = 1;

				event.target.value = curVal.toString();

				item.quantity = curVal;
				this.showAmountOfProductItem(item);
				this.showTotalAmountFromList();
			},
            showTotalAmountFromList() {
				try {
					// total number of product in list
					this.numberOfProducts = this.listProductOfOrder.length;

					// total amount = price * quantity
					this.totalAmount = this.listProductOfOrder.reduce((acc, item) => acc + (item.price * item.quantity), 0);

					// total Debt Points
					this.totalDebtPoints = this.listProductOfOrder.reduce((acc, item) => acc + (item.debtPoints * item.quantity), 0);

					// final amount = totalAmount + totalDebtPoints * 1000
					this.provisionalAmount = this.totalAmount + (this.totalDebtPoints * 1000);
                } catch (ex) {
                    console.log('showTotalAmountFromList ex: ', ex)
                }
			},
            onBuying() {
                this.onAddDealerOrders();
            },
            onGoBack() {
                this.$router.push('/warehouses/receivelist');
			},           
            async onAddDealerOrders() {               			
							
                if (this.agentOrderDetail.buyingAgentId === 0 && this.isShowAgentList) {
                    this.$store.dispatch('alert/error', this.$t('pages.warehouse.selectAgent'));
                    return;
				}

                if (this.listProductOfOrder == null || this.listProductOfOrder.length === 0) {
                    this.$store.dispatch('alert/error', this.$t('pages.warehouse.selectProduct'));
                    return;
				}

                if (this.agentOrderDetail.shippingAddressId == null || this.agentOrderDetail.shippingAddressId < 0) {
                    this.$store.dispatch('alert/error', this.$t('common.selectShippingAddress'));
                    return;
                }				

				for (var i = 0; i < this.listProductOfOrder.length; i++) {
					if (this.listProductOfOrder[i].quantity === 0) {
                        this.$store.dispatch('alert/error', this.$t('pages.warehouse.enterQuantity'));
                        return;
                    }
                }                                              

				this.agentOrderDetail.agentOrderItems = this.listProductOfOrder;
                this.agentOrderDetail.amount = this.totalAmount;
				this.agentOrderDetail.debtPoints = this.totalDebtPoints;
				this.agentOrderDetail.numberOfProducts = this.listProductOfOrder.length;

				var retVal = await this.$store.dispatch("transferInventory/saveDealerOrders");
				
                if (retVal != null && (retVal.success || retVal.data)) {
                    //this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.warehouse.noticeDistributeSuccess')  });
					//this.$router.push('/warehouses/receivelist');                   
                    this.$router.push('/warehouses/NoticeDealerOrder/' + retVal.errorCode);
				}
				else {
					var mess = i18n.t('common.unsuccessfull');
                    if (retVal != null && retVal.errorMessage != '') {
						switch (retVal.errorMessage) {
                            case 'NotEnoughQuantity': mess = i18n.t('pages.warehouse.notEnoughQuantity'); break;
							case 'Invalid Receiver': mess = i18n.t('pages.warehouse.selectAgent'); break;
							case 'Invalid Product': mess = i18n.t('pages.warehouse.selectProduct'); break;
                            case 'Invalid Price': mess = i18n.t('pages.warehouse.enterPrice'); break;
							case 'Invalid Quantity': mess = i18n.t('pages.warehouse.enterQuantity'); break;
                            case 'Invalid RetailUnit': mess = i18n.t('common.invalidRetailUnit'); break;
							case 'Invalid UnitPacking': mess = i18n.t('common.invalidUnitPacking'); break;
							case 'Invalid Manager': mess = i18n.t('pages.manager.agentHaveNoSales'); break;                            
						}
					}

                    this.$store.dispatch('alert/error', mess);
				}				
			},
            onSearchProduct() {
                this.searchProducts();
            },
            searchProducts() {
                var searchCriteria = {
                    searchProductName: this.searchProductName
                }
                this.$store.dispatch("product/getAllProducts", searchCriteria)
            },

            onItemsPerPageChange(item) {
                localStorage.setItem('productItemsPerPage', item)
			},
			onAddToOrders(item) {
				if (this.listProductOfOrder == null)
					this.listProductOfOrder = [];

				let _wholesalePoints = item.wholesalePoints;
				if (isNaN(_wholesalePoints))
					_wholesalePoints = 0;

				let productItem = {
					productId: item.id,
                    name: item.name,
					price: item.wholesalePrice,
                    debtPoints: _wholesalePoints,
                    quantity: 1
				}

				if (this.listProductOfOrder.length === 0)
				{
					this.listProductOfOrder.push(productItem);
                }
				else {                    

					// if the product already exists in the list, increase the quantity by 1
					let index = this.listProductOfOrder.findIndex(elem => elem.productId === productItem.productId);               				

					if (index != null && index >= 0) {
						this.listProductOfOrder[index].quantity += 1;
					}
					else {
                        this.listProductOfOrder.push(productItem);
                    }
                }

				this.showTotalAmountFromList();				
			},
			onRemoveProductFromList(index) {
                this.$refs.confirmation.show(i18n.t('pages.product.confirmDeleteProduct'), () => {
					this.listProductOfOrder.splice(index, 1);
                    this.showTotalAmountFromList();
                });
			},
			showAmountOfProductItem(item) {
				let _amount = 0;
				try {
                    _amount = item.price * item.quantity;                   
				} catch (ex) {
                    console.log('showAmountOfProductItem ex: ', ex)
				}
				
                return this.$func.formatCurrency(_amount);
			},
            onAddRemoveQuantity(isAddQuantity, item, index) {
				
                if (isAddQuantity) {
                    item.quantity += 1;
                }
                else {
                    let quantity = item.quantity - 1;

                    if (quantity < 0)
                        quantity = 0;

                    item.quantity = quantity;
                }

				this.showAmountOfProductItem(item);

				if (item.quantity <= 0) {
                    this.$refs.confirmation.show(i18n.t('pages.product.confirmDeleteProduct'), () => {
						this.listProductOfOrder.splice(index, 1);
                    });
				}

                this.showTotalAmountFromList();
			},
			onAddShippingAddress() {
                this.$router.push('/address/add-address');
				//this.$store.commit('showAddressSidebar');
			}
		},
		mounted() {
			this.$store.dispatch("transferInventory/initNewTransferInventory");
            this.$store.dispatch("location/getShippingAddress");  

			let role = this.$store.state.auth.user.role;          
            if (role === 'sale') {
                this.isShowAgentList = true;
                this.$store.dispatch("agent/getAgentList", { parentId: this.$store.state.auth.user.ag });
			}
            				
            let productItemsPerPage = parseInt(localStorage.getItem('productItemsPerPage'));
            if (isNaN(productItemsPerPage))
                productItemsPerPage = 10;
            this.itemsPerPage = productItemsPerPage;
           
            this.searchProducts();
		}
	})
</script>

<style scoped>
	.quantity {
		width: 120px; height:30px; border:0px solid red; float:left; display:inline-block
	}
    .quantity-minus {
        width: 30px;
        height: 27px;
        border: 1px solid gray;
        float: left;
		text-align:center;
    }
    .quantity-plus {
        width: 30px;
        height: 27px;
        border: 1px solid gray;
        float: left;
        text-align: center;
    }
    .quantity-input {
        width: 50px;
        float: left; text-align: center
    }
</style>